import React from "react"
import Helmet from "react-helmet"

import "../styles/all.css"

const layout = ({ children, bodyClass='' }) => (
  <>
    <Helmet bodyAttributes={{class: bodyClass}}>
      <title>株式会社ブイ・フォース / vforce</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
      <meta name="description" content="ブイ・フォースはスタートアップを支えるために生まれました。有名スタートアップの創業期を乗り越え、上場へと導いたメンバーを中心としたシステムインテグレーター。その体験がわたしたちのアイデンティティの中心。スタートアップを支たいという意志です。スタートアップという勇敢な挑戦者であるあなたを応援し、少しでも世の中が変わることに貢献したい。スタートアップのためにスタートアップ専門を選んだシステムインテグレーターです。" />
      <meta name="keywords" itemprop="keywords" content="ブイ・フォース,成果報酬,レベニューシェア,システム,Web,iOS,Andorid,開発" />
      <meta property="og:title" content="株式会社ブイ・フォース / vforce" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://v-force.co.jp" />
      <meta property="og:image" content="https://v-force.co.jp/ogp-img.png" />
      <meta property="og:site_name" content="株式会社ブイ・フォース / vforce" />
      <meta property="og:description" content="ブイ・フォースはスタートアップを支えるために生まれました。有名スタートアップの創業期を乗り越え、上場へと導いたメンバーを中心としたシステムインテグレーター。その体験がわたしたちのアイデンティティの中心。スタートアップを支たいという意志です。スタートアップという勇敢な挑戦者であるあなたを応援し、少しでも世の中が変わることに貢献したい。スタートアップのためにスタートアップ専門を選んだシステムインテグレーターです。" />
      <meta property="fb:app_id" content="1522584371181702" />
      <meta name="twitter:card" content="summarylargeimage" />
      <meta name="google-site-verification" content="eyRwShfkuy72Kl5ZZcuIU0S0FrwwfzvSK0wXQEZuOu8" />
      <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
    </Helmet>
    {children}
    <Helmet>
      <script nomodule src="https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/7.6.0/polyfill.min.js" crossorigin="anonymous"></script>
      <script nomodule src="https://polyfill.io/v3/polyfill.min.js?features=Object.assign%2CElement.prototype.append%2CNodeList.prototype.forEach%2CCustomEvent%2Csmoothscroll" crossorigin="anonymous"></script>
      <script src="/bundle.js" type="module"></script>
    </Helmet>
  </>
)

export default layout;
